import React, { useState, useEffect } from "react";
import Carousel1 from "../img/Carousel1.jpg";
import Carousel2 from "../img/Carousel2.jpg";
import Carousel3 from "../img/Carousel3.jpg";
import Carousel4 from "../img/Carousel4.jpg";
import Carousel1320 from "../img/Carousel1-320.jpg";
import Carousel2320 from "../img/Carousel2-320.jpg";
import Carousel3320 from "../img/Carousel3-320.jpg";
import Carousel4320 from "../img/Carousel4-320.jpg";
import SwipeIcon from "../img/giphy.gif";
import Countdown from "react-countdown";
import CustomCountdownRenderer from "./CustomCountdownRenderer";
import MusicPlayer from "./MusicPlayer";

const addEventToGoogleCalendar = () => {
  const title = "💍🤵🏽👰🏽 Boda Micaela y Carlos";
  const description = `Ceremonia Religiosa: Sábado 22 de Febrero - 20:30 horas. Pquia San Juan Bosco (Av. Mitre 312)<br/>Fiesta: Sábado 22 de Febrero - 22:00 horas. Salón Keké House (Club de Campo Las Moritas - Ruta 305)<br/><a href="https://micaelaycarlos.invitapp.com.ar">Ver invitación online</a>`;
  const location = "https://maps.app.goo.gl/CwXraJhG28KRT3HV9";

  const startDate = new Date("2025-02-22T20:30:00");
  const endDate = new Date("2025-02-23T05:00:00");

  const formatDate = (date) => {
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${year}${month}${day}T${hours}${minutes}00Z`;
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const calendarUrl = `https://www.google.com/calendar/event?action=TEMPLATE&text=${title}&details=${description}&location=${location}&dates=${formattedStartDate}/${formattedEndDate}`;

  window.open(calendarUrl);
};

const Carousel = ({ play, setPlay, isFinished, setIsFinished }) => {
  const currentDate = new Date();
  const targetDate = new Date("2025-02-22T00:00:00");


  const adjustedCurrentDate = new Date(
    currentDate.toLocaleString("en-US", { timeZone: "America/Buenos_Aires" })
  );

  const timeUntilTarget = targetDate.getTime() - adjustedCurrentDate.getTime();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const isEqual = 
      adjustedCurrentDate.getFullYear() === targetDate.getFullYear() &&
      adjustedCurrentDate.getMonth() === targetDate.getMonth() &&
      adjustedCurrentDate.getDate() === targetDate.getDate();
  
    setIsFinished(isEqual);
  }, [adjustedCurrentDate, targetDate, setIsFinished]);
  
  
  
  const carouselItems = [
    {
      src: windowWidth < 768 ? Carousel1320 : Carousel1,
      alt: "carousel-image1",
    },
    {
      src: windowWidth < 768 ? Carousel2320 : Carousel2,
      alt: "carousel-image2",
    },
    {
      src: windowWidth < 768 ? Carousel3320 : Carousel3,
      alt: "carousel-image3",
    },
    {
      src: windowWidth < 768 ? Carousel4320 : Carousel4,
      alt: "carousel-image4",
    },
  ];

  return (
    <div className="container-fluid p-0 vanish" id="home">
      <div id="player-container">
        <MusicPlayer play={play} setPlay={setPlay} />
      </div>
      <div
        id="header-carousel"
        className="carousel slide carousel-fade"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          {carouselItems.map((item, index) => (
            <div
              key={index}
              className={`carousel-item position-relative ${
                index === 0 ? "active" : ""
              }`}
              style={{ height: "100vh", minHeight: "400px" }}
            >
              <img alt={item.alt} className="carousel-image" src={item.src} />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center married">
                <div className="p-3" style={{ maxWidth: "900px" }}>
                  <div className="d-inline-block border-top border-bottom border-light py-3 px-4 top-married">
                    <h3
                      className="text-uppercase font-weight-normal text-white m-0 getting-married nos-casamos"
                      style={{ letterSpacing: "5px" }}
                    >
                      ¡NOS CASAMOS!
                    </h3>
                  </div>
                  <h1
                    className="display-1 font-secondary text-white mt-n3 mb-md-4 couple-title"
                    style={{ fontFamily: "MicaelayCarlos1" }}
                  >
                    Micaela & Carlos
                  </h1>
                  <div className="d-inline-block border-top border-bottom border-light py-3 px-4 bottom-married">
                    <h3
                      className="text-uppercase font-weight-normal text-white m-0 getting-married"
                      style={{ letterSpacing: "5px" }}
                    >
                      ¡NOS CASAMOS!
                    </h3>
                  </div>

                  <div>
                    <Countdown
                      date={currentDate.getTime() + timeUntilTarget}
                      renderer={CustomCountdownRenderer}
                      zeroPadTime={2}
                    />
                    <img
                      alt="swipe-icon"
                      className="swipe-icon"
                      src={SwipeIcon}
                    />
                    {!isFinished ? (
                      <div className="save-the-date-container">
                        <button
                          onClick={addEventToGoogleCalendar}
                          className="gold-button shine-button"
                        >
                          SAVE THE DATE
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <a
          className="carousel-control-prev justify-content-start"
          href="#header-carousel"
          data-slide="prev"
        >
          <div
            className="btn btn-primary px-0"
            style={{ width: "68px", height: "68px" }}
          >
            <span className="carousel-control-prev-icon mt-3"></span>
          </div>
        </a>
        <a
          className="carousel-control-next justify-content-end"
          href="#header-carousel"
          data-slide="next"
        >
          <div
            className="btn btn-primary px-0"
            style={{ width: "68px", height: "68px" }}
          >
            <span className="carousel-control-next-icon mt-3"></span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Carousel;
