import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AnimatedDiv from "./AnimatedDiv";

const galleryImages = [
  { src: require("../img/Gallery1.jpg"), position: "0px -220px" },
  { src: require("../img/Gallery2.jpeg"), position: "0px -200px" },
  { src: require("../img/Gallery3.jpg"), position: "0px -150px" },
  { src: require("../img/Gallery4.jpeg"), position: "0px 0px" },
  { src: require("../img/Gallery5.jpg"), position: "0px -230px" },
  { src: require("../img/Gallery6.jpeg"), position: "0px -240px" },
  { src: require("../img/Gallery7.jpg"), position: "0px -300px" },
  { src: require("../img/Gallery8.jpg"), position: "0px -300px" },
  { src: require("../img/Gallery9.jpg"), position: "0px -190px" },
  { src: require("../img/Gallery10.jpg"), position: "0px -300px" },
  { src: require("../img/Gallery11.jpeg"), position: "0px -230px" },
  { src: require("../img/Gallery12.jpeg"), position: "0px -250px" },
];

const Gallery = () => {
  const PrevArrow = ({ onClick }) => (
    <button className="carousel-arrow prev" onClick={onClick}>
      <i className="fa fa-angle-left carousel-icon" aria-hidden="true"></i>
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button className="carousel-arrow next" onClick={onClick}>
      <i className="fa fa-angle-right carousel-icon" aria-hidden="true"></i>
    </button>
  );

  const settings = {
    autoplay: false,
    speed: 1500,
    dots: false,
    infinite: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="container-fluid bg-gallery"
      id="gallery"
      style={{ padding: "120px 0", margin: "90px 0" }}
    >
      <AnimatedDiv>
        <div
          className="section-title position-relative text-center"
          style={{ marginBottom: "120px" }}
        >
          <h6
            className="text-uppercase mb-3"
            style={{ letterSpacing: "3px", color: "white", opacity: "60%" }}
          >
            NUESTROS MOMENTOS JUNTOS
          </h6>
          <h1 className="font-secondary display-4 text-white">Galería</h1>
          <i className="far fa-heart text-white"></i>
        </div>
      </AnimatedDiv>
      <Slider
        {...settings}
        className="gallery-carousel"
        style={{ marginTop: "-50px" }}
      >
        {galleryImages.map((image, index) => (
          <div
            key={index}
            className="gallery-item"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="img-fluid"
              src={image.src}
              alt=""
              style={{ objectPosition: image.position }}
            />
            <a href={image.src} data-lightbox="gallery">
              <i className="fas fa-search-plus text-white"></i>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Gallery;
