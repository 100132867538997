import React from "react";

const InitialPage = ({ setIsRedirecting, setIsLoading }) => {
  // const handleFamiliarRedirect = () => {
  //   setIsLoading(true);
  //   setIsRedirecting(true);
  //   setIsFamiliar(true);
  // };

  const handleFriendRedirect = () => {
    setIsLoading(true);
    setIsRedirecting(true);
  };

  return (
    <div className="initial-page">
      <div>
        <p className="initial-text">Micaela & Carlos</p>
        <p className="secondary-text">
          Nuestra historia de amor continúa <br /> y queremos que seas parte de
          ella
        </p>
      </div>
      <div>
        <div className="initial-button-container">
          {" "}
          <button className="initial-button" onClick={handleFriendRedirect}>
            EMPEZAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialPage;
