import React from "react";
import { Carousel } from "react-responsive-carousel";
import Testimonials1 from "../img/Testimonials1.jpg";
import Testimonials2 from "../img/Testimonials2.jpg";
import Testimonials3 from "../img/Testimonials3.jpg";
import Testimonials4 from "../img/Testimonials4.jpg";
import Testimonials5 from "../img/Testimonials5.jpg";
import Testimonials6 from "../img/Testimonials6.jpg";
import AnimatedDiv from "./AnimatedDiv";

const testimonialsData = [
  {
    img: Testimonials6,
    text: "Mis queridos hijos Mica y Carlitos, si porque a partir de ahora tengo un hijo más... Les deseo toda la felicidad del mundo para ustedes, se lo merecen. Muchas bendiciones.",
    name: "Mónica Correa",
  },
  {
    img: Testimonials5,
    text: "Mis mejores deseos para un futuro repleto de amor y felicidad.",
    name: "Beatríz Juárez",
  },
  {
    img: Testimonials1,
    text: "Que en este camino juntos sean muy felices, les deseo todo el amor del mundo para ustedes que son super incondicionales conmigo. Los amo.",
    name: "Alejandra Pérez",
  },
  {
    img: Testimonials2,
    text: "El amor verdadero siempre encontrará la manera de permanecer y triunfar. Que este nuevo comienzo sea para seguir construyendo ese sueño que comenzaron dos niños enamorados. Los adoro un montón y deseo toda la felicidad para su matrimonio.",
    name: "Romina Rivero",
  },
  {
    img: Testimonials3,
    text: "Queridos sobrinos deseo que su matrimonio este lleno de felicidad y mucho amor. Es una gran bendición de Dios saber que van a estar el uno para el otro hasta la eternidad. Los amamos.",
    name: "David y Jeremías",
  },
  {
    img: Testimonials4,
    text: "Amigos del alma soy un privilegiado de conocer desde el principio su historia. En este nuevo comienzo les deseo toda la felicidad que se merecen. Gracias por compartir los bellos momentos conmigo y mi familia, aquí estamos para acompañarlos en cada paso. Los amo.",
    name: "Mariano Giménez",
  },
];

const Testimonials = () => {
  return (
    <div className="container-fluid py-2 carousel-container" id="about">
      <div className="container py-5">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h6
              className="text-uppercase text-primary mb-3 mt-3"
              style={{ letterSpacing: "3px" }}
            >
              DESEOS DE NUESTROS
            </h6>
            <h1 className="font-secondary display-4 mb-3">Seres Queridos</h1>
            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div>
          <Carousel
            infiniteLoop={true}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            swipeable={false}
            renderArrowPrev={(onClickHandler, hasPrev) =>
              hasPrev && (
                <button
                  onClick={onClickHandler}
                  className="carousel-arrow-testimonials prev"
                >
                  <i
                    className="fas fa-angle-left"
                    style={{ color: "#58a475", fontSize: "25px" }}
                  ></i>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <button
                  onClick={onClickHandler}
                  className="carousel-arrow-testimonials next"
                >
                  <i
                    className="fas fa-angle-right"
                    style={{ color: "#58a475", fontSize: "25px" }}
                  ></i>
                </button>
              )
            }
          >
            {testimonialsData.map(({ img, text, name }, index) => (
              <div key={index}>
                <img alt="" src={img} className="testimonials-photos" />
                <p className="mt-3">{text}</p>
                <h3 className="font-secondary font-weight-normal text-muted mb-3">
                  {name}
                </h3>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
