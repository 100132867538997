import React from "react";
import MusicalNotes from "../img/musical-notes.png";
import Playlistlogo from "../img/spotify.png";
import AnimatedDiv from "./AnimatedDiv";

const Playlist = () => {
  return (
    <div className="container-fluid bg-secondary py-2" id="about">
      <div className="container py-5">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h6
              className="text-uppercase text-primary mb-3 mt-3"
              style={{ letterSpacing: "3px" }}
            >
              Ayudanos con la
            </h6>
            <h1 className="font-secondary display-4 mb-3">Música</h1>
            <h6
              style={{
                letterSpacing: "2px",
                color: "#474747",
                margin: "0 auto",
                marginBottom: "20px",
              }}
            >
              Compartinos ESA canción que no puede faltar en nuestra fiesta!
            </h6>
            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div className="row m-0">
          <div className="col-md-12 p-0">
            <div className="h-100 d-flex flex-column justify-content-center">
              <img
                alt="music"
                src={MusicalNotes}
                style={{ width: "20%", margin: "0 auto", marginBottom: "20px" }}
              />
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://forms.gle/QdBdt1BFv3xrKaea9"
                  className="mr-md-3"
                >
                  <button className="blue-button">RECOMENDAR</button>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://open.spotify.com/playlist/7MRkRTiFDY6m9C3uxEMkbE?si=a5f528a1c4094894"
                >
                  <button className="blue-button">
                    IR A PLAYLIST
                    <img
                      src={Playlistlogo}
                      style={{ width: "20px", marginLeft: "10px" }}
                    />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playlist;
