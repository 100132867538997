import React, { useEffect } from "react";

const Loader = ({ setIsRedirecting }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRedirecting(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [setIsRedirecting]);
  return (
    <div className="loader-page">
      <div>
        <p className="loader-text">Micaela & Carlos</p>
        <span class="loader"></span>
      </div>
    </div>
  );
};

export default Loader;
