import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import Story1 from "../img/Story1.jpg";
import Story2 from "../img/Story2.jpg";
import Story3 from "../img/Story3.jpg";

const Story = () => {
  return (
    <div className="container-fluid" id="story">
      <div className="container pb-3">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h6
              className="text-uppercase text-primary mb-3"
              style={{ letterSpacing: "3px" }}
            >
              Un poco de
            </h6>
            <h1 className="font-secondary display-4">Nuestra historia</h1>
            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div className="container timeline position-relative p-0">
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story1} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2 text-primary-two">Nos conocimos</h4>
                <p className="m-0">
                  en el año 2011. Ibamos al mismo colegio pero nunca nos
                  habíamos visto hasta que empezamos a ser compañeros.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 mr-md-3">
                <h4 className="mb-2 text-primary-two">Nuestro primer beso</h4>
                <p className="m-0">
                  fue en el mes de Agosto de 2012 en una fiesta del colegio y,
                  desde ese momento, nunca más nos separamos. El 9 de Septiembre
                  de 2012 nos pusimos de novios, la propuesta de noviazgo fue de
                  Micaela.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <img className="img-fluid ml-md-3" src={Story2} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story3} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2 text-primary-two">Nos comprometimos</h4>
                <p className="m-0">
                  el 28 de Julio de 2024, en un encuentro con nuestros mejores
                  amigos en la provincia de Jujuy. Llegó la propuesta de
                  casamiento por parte de Carlitos, ayudado por su mejor amigo.
                  En una reunión íntima con gente que nos quiere y queremos. La
                  propuesta PERFECTA.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
