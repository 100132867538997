import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import Groom from "../img/Groom.jpg";
import Bride from "../img/Bride.png";

const Invitation = () => {
  return (
    <div className="container-fluid py-5" id="invitation">
      <div className="container py-5">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h1 className="font-secondary display-4 mb-3">¡Hola!</h1>
            <h6 style={{ letterSpacing: "2px", color: "#474747" }}>
              Con mucha alegría te invitamos a celebrar con nosotros nuestra
              boda
            </h6>
            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div className="row m-0 mb-4 mb-md-0 pb-2 pb-md-0">
          <div className="col-md-6 p-0 text-center text-md-right">
            <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-5">
              <h4 className="mb-3 text-primary-two">El novio</h4>
              <h1 className="font-secondary font-weight-normal text-muted mb-3">
                Carlos Maximiliano Pérez
              </h1>
              <p>
                Profe de Educación Física. Malabarista. Amante del fútbol,
                pasión que heredó de su padre. Le gustan los libros, la música y
                el mate.
              </p>
            </div>
          </div>
          <div className="col-md-6 p-0" style={{ minHeight: "400px" }}>
            <img
              alt="groom"
              className="w-100 h-100"
              src={Groom}
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-md-6 p-0" style={{ minHeight: "400px" }}>
            <img
              alt="bride"
              className="w-100 h-100"
              src={Bride}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-md-6 p-0 text-center text-md-left">
            <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-5">
              <h4 className="mb-3 text-primary-two">La novia</h4>
              <h1 className="font-secondary font-weight-normal text-muted mb-3">
                Micaela Juárez
              </h1>
              <p>
                Profesora de Lengua y Literatura. Estudiante. Hermana del medio.
                Impulsiva y aventurera. Amante de las reuniones con amigos y
                familia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;
